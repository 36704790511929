import React, { useMemo } from 'react';

import variables from '../../configs/variables';
import {
  formatCoinsAmount,
  getInputsAmount,
  getOutputsAmount,
} from '../../helpers';

const TransactionFee = ({ inputs, outputs, txInfo }) => {
  const fee = useMemo(() => {
    const inputsAmount = getInputsAmount(txInfo, inputs);
    const outputsAmount = getOutputsAmount(outputs);

    return (inputsAmount - outputsAmount) / variables.coinParts;
  }, [inputs.length, outputs.length]);

  return (
    <>
      {formatCoinsAmount(fee)} {variables.coinSymbol}
    </>
  );
};

export { TransactionFee };
