import React from 'react';

import { InfoCard } from './InfoCard';
import { CoinSupplyIcon } from '../../icons';
import {
  formatBigNumber,
  formatDateTime,
  formatNumber,
  formatPercent,
  formatReward,
} from '../../helpers';
import variables from '../../configs/variables';
import useStats from '../../contexts/stats-context';

const CoinSupplyInfoCard = () => {
  const {
    totalSupply,
    maxSupply,
    blockReward,
    halvingTimestamp,
    halvingAmount,
  } = useStats();

  return (
    <InfoCard
      icon={CoinSupplyIcon}
      title='Coin supply'
      params={{
        Total: `${formatBigNumber(totalSupply)} ${variables.coinSymbol}`,
        'Max (approx.)': `${formatBigNumber(maxSupply)} ${variables.coinSymbol}`,
        Mined: formatPercent((totalSupply / maxSupply) * 100),
        'Block reward': `${formatReward(blockReward)} ${variables.coinSymbol}`,
        'Reward reduction': `${formatDateTime(halvingTimestamp)} to ${formatReward(halvingAmount)} ${variables.coinSymbol}`,
      }}
    />
  );
};

export { CoinSupplyInfoCard };
