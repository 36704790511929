import variables from '../configs/variables';
import { formatDateTime } from './formatting';

const getAddressKeyFromOutputs = (outputs, index = 0) => {
  const address = getAddressFromOutputs(outputs, index);
  if (!address) {
    return 'unknown';
  }
  return address.script_public_key_address;
};

const getAmountFromOutputs = (outputs, index = 0) => {
  const address = getAddressFromOutputs(outputs, index);
  if (!address) {
    return 0;
  }
  return address.amount / variables.coinParts;
};

const getAddressFromOutputs = (outputs, index) => {
  if (!outputs) {
    return null;
  }
  return outputs.find((output) => output.index === index);
};

const getTransactionAmount = (tx) => {
  return tx.outputs.reduce((sum, output) => {
    const amount = output.amount || 0;
    return sum + amount / variables.coinParts;
  }, 0);
};

const getTransactionTimestamp = (tx) => {
  return tx ? formatDateTime(tx.block_time / 1000) : null;
};

const convertTxsArrayToMap = (txs) => {
  return txs.reduce((map, tx) => {
    map[tx.transaction_id] = tx;
    return map;
  }, {});
};

const getOutputByIndex = (outputs, index) => {
  if (!outputs) {
    return {};
  }
  return outputs.find((output) => output.index === index);
};

const getInputsAmount = (txInfo, inputs) => {
  const amounts = inputs.map((input) => getInputAmount(txInfo, input));
  return sum(amounts);
};

const getOutputsAmount = (outputs) => {
  const amounts = outputs.map((output) => output.amount);
  return sum(amounts);
};

const getInputAmount = (txInfo, input) => {
  const output = getOutputByIndex(
    txInfo[input.previous_outpoint_hash]?.outputs || [],
    +input.previous_outpoint_index,
  );
  return output?.amount ?? 0;
};

const sum = (arr) => {
  return arr.reduce((a, b) => a + b, 0);
};

export {
  getAddressKeyFromOutputs,
  getAmountFromOutputs,
  getTransactionAmount,
  getTransactionTimestamp,
  convertTxsArrayToMap,
  getOutputByIndex,
  getInputsAmount,
  getOutputsAmount,
};
